<template>
  <div class="row animated fadeIn">
    <div class="col-lg-12 d-flex flex-column">
      <div class="d-flex justify-content-end">
        <button v-if="!isDisable" class="btn btn-info btn-md" @click="openModal(true)">
          {{ $t('vouchers.createHotelVouchers') }}
        </button>
      </div>

<!--      <Table-pagination-v2-->
<!--        :on-submit="getWithQuery.bind(this)"-->
<!--        v-model="filters"-->
<!--        :show-filters="['search', 'sort', 'limit']"-->
<!--        :options="[-->
<!--          { value: 'account', label: $t('vouchers.account') },-->
<!--          { value: 'expire_at', label: $t('vouchers.expire_at') },-->
<!--          { value: 'profile', label: $t('vouchers.profile') },-->
<!--          { value: 'used', label: $t('vouchers.used') }-->
<!--        ]"-->
<!--      />-->
      <Table-pagination-v3
        v-if="showTablePaginationV3Component"
        small-width-location-selector
        small-height-for-custom-multiselect-and-select-components
        :on-submit="getWithQuery.bind(this)"
        v-model="filters"
        :show-filters="['search', 'sort', 'limit', 'location']"
        :options="[
          { value: 'account', label: $t('vouchers.account') },
          { value: 'expire_at', label: $t('vouchers.expire_at') },
          { value: 'profile', label: $t('vouchers.profile') },
          { value: 'used', label: $t('vouchers.used') }
        ]"
      />
      <div class="row">
              <div class="col-lg-4">
                <div class="filters__multiple">
                  <div class="input-group input-group-sm filters__wlan flex-wrap mr-1">
                    <button
                      v-if="isFiltersActive"
                      class="btn-outline-secondary btn btn-sm  new-modern-style-btn-in-filters mt-0"
                      @click.stop.prevent="resetAllFilters"
                    >
                      <i class="fa fa-close"></i>
                      {{ $t('general.resetAllFilters') }}
                    </button>
                  </div>
                </div>
              </div>

      </div>
    </div>

    <div class="col-lg-12">
      <div class="card">
        <!--        <div class="loader loader-backdrop loader-backdrop-portalvouchers" v-if="isVouchersLoading"></div>-->
        <div v-if="isVouchersLoading"><Loader-spinner /></div>
        <div class="card-header d-flex justify-content-between">
          <div class="d-flex align-items-center">
            <i class="glyphicons glyphicons-fees-payments glyphicons--shrink mr-0"></i>
            {{ $t('vouchers.titleHotel') }}
            <span class="text-muted ml-3" v-if="vouchersList.length">
              {{ this.vouchersList.length }} {{ this.$t('portal.paginationOf') }} {{ this.totalResultVouchers }}
            </span>
          </div>
          <div class="d-flex align-items-center">
<!--            <download-extract-v2-->
<!--              class="ml-h"-->
<!--              :data="vouchersDataForExport"-->
<!--              type="vouchers"-->
<!--              :text="$t('vouchers.download')"-->
<!--              :filename="`hotel-vouchers-${moment(new Date()).format('lll')}`"-->
<!--            />-->
            <download-extract-v2
              newXlsAutoColumnsWidth
              :excel-file-name="getFileNameForDownloadFiles($t('downloadFilesNames.portalHotelVouchers'))"
              :data-for-excel="convertToNewExcelExportModuleFormat(vouchersDataForExport, $t('vouchers.titleHotel'))"
              class="ml-h"
              :data="vouchersDataForExport"
              :text="$t('vouchers.download')"
              :filename="getFileNameForDownloadFiles($t('downloadFilesNames.portalHotelVouchers'))"
              :downloads-formats-for-show="{
                oldCsv: true,
                newCsv: false,
                oldXls: false,
                newXls: true,
                pdf: true
              }"
            />
          </div>
        </div>
        <div class="card-block card-block__table table-responsive">
          <!-- <h5 class="mb-1"></h5> -->
          <!-- <div class="row">
          <div class="col-lg-12">-->
          <table class="table table-hover">
            <thead>
              <tr>
                <th></th>
                <th scope="col" class="text-nowrap align-middle">{{ $t('vouchers.account') }}</th>
                <th scope="col" class="text-nowrap align-middle">{{ $t('vouchers.profile') }}</th>
                <th scope="col" class="text-nowrap align-middle">{{ $t('vouchers.duration') }}</th>
                <th scope="col" class="text-nowrap align-middle">{{ $t('vouchers.code') }}</th>
                <th scope="col" class="text-nowrap align-middle">{{ $t('vouchers.used') }}</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(voucher, index) in vouchersList"
                :key="voucher.id"
                @click="openModal(false, voucher)"
                :class="{ 'expired-voucher ': new Date() > voucher.expire_at * 1000 ? 'red' : '' }"
              >
                <td class="align-middle">{{ index + 1 }}</td>
                <td class="align-middle">
                  <span v-if="voucher.account">
                    {{ getAccountNameByID(voucher.account) || '-' }}
                  </span>
                  <EmptyCellValue v-else />
                </td>
                <td class="align-middle">
                  <span>
                    {{ getProfileNameByID(voucher.profile) }}
                  </span>
                </td>
                <td class="align-middle">
                  <span v-if="voucher.start_at || voucher.expire_at">
                    {{ getDuration(voucher.start_at, voucher.expire_at) || '-' }}
                  </span>
                  <EmptyCellValue v-else />
                </td>
                <td class="align-middle">
                  <span v-if="voucher.code">
                    {{ voucher.code }}
                  </span>
                  <EmptyCellValue v-else />
                </td>
                <td class="align-middle">
                  <h5>
                    <span
                      class="badge font-weight-normal"
                      :class="{ 'badge-success': voucher.used === true, 'badge-warning': voucher.used === false }"
                    >
                      {{ voucher.used ? $t('general.yes') : $t('general.no') }}
                    </span>
                  </h5>
                </td>
              </tr>
            </tbody>
          </table>
          <!-- </div>
          </div>-->
          <div
            v-if="
              this.canLoadMoreVouchers &&
              this.vouchersList.length &&
              this.totalResultVouchers - this.vouchersList.length
            "
            class="card-block text-center p-0"
          >
            <button class="btn btn-block" @click="loadMore">
              {{ this.$t('portal.paginationLoad') }}
              <span v-if="this.filters.limit < this.totalResultVouchers - this.vouchersList.length">
                {{ this.filters.limit }}
              </span>
              <span v-else>{{ this.totalResultVouchers - this.vouchersList.length }}</span>
              {{ this.$t('portal.paginationVouchersOutOf') }} {{ this.totalResultVouchers - this.vouchersList.length }}
            </button>
          </div>
          <!--            </div>-->
          <!--          </div>-->
        </div>
      </div>
    </div>

    <modal
      :backdrop="false"
      @click.native="handleModalClick"
      class="modal-info"
      closeBtn
      effect="fade/zoom"
      v-model="isModalOpen"
    >
      <h4 slot="modal-title" class="modal-title">
        {{ isNew ? $t('vouchers.newHotelVouchers') : $t('vouchers.editHotel') }}
      </h4>

      <div class="form-group">
        <label for="profile" class="required-mark">{{ $t('vouchers.profile') }}</label>
        <select
          id="profile"
          name="profile"
          class="form-control"
          v-validate="'required'"
          :data-vv-as="$t('vouchers.profile')"
          v-model="voucher.profile"
          :disabled="isDisable"
        >
          <option :key="profile.id" v-for="profile in portalProfilesList" :value="profile.id">
            {{ getProfileNameByID(profile.id) }}
          </option>
        </select>
        <span
          v-tooltip.top-center="{ content: errors.first('profile') }"
          v-show="errors.has('profile')"
          class="help is-danger error_tooltip"
        >
          <i class="fa fa-warning text-error"></i>
        </span>
      </div>

      <div v-if="isNew" class="form-group">
        <label for="codeLength" class="required-mark">{{ $t('vouchers.codeLength') }}</label>
        <input
          id="codeLength"
          name="codeLength"
          type="number"
          class="form-control"
          v-validate="'required|integer'"
          :data-vv-as="$t('vouchers.codeLength')"
          v-model.number="voucher.length_voucher"
          :disabled="isDisable"
        />
        <span
          v-tooltip.top-center="{ content: errors.first('codeLength') }"
          v-show="errors.has('codeLength')"
          class="help is-danger error_tooltip"
        >
          <i class="fa fa-warning text-error"></i>
        </span>
      </div>
      <div v-if="isNew" class="form-group">
        <label for="number_of_vouchers" class="required-mark">{{ $t('vouchers.number_of_vouchers') }}</label>
        <input
          id="number_of_vouchers"
          name="number_of_vouchers"
          type="number"
          class="form-control"
          v-validate="'required|integer'"
          :data-vv-as="$t('vouchers.number_of_vouchers')"
          v-model.number="voucher.number_of_vouchers"
          :disabled="isDisable"
        />
        <span
          v-tooltip.top-center="{ content: errors.first('number_of_vouchers') }"
          v-show="errors.has('number_of_vouchers')"
          class="help is-danger error_tooltip"
        >
          <i class="fa fa-warning text-error"></i>
        </span>
      </div>

      <div class="form-group">
        <label for="duration" class="required-mark">{{ $t('vouchers.duration') }} ({{ $t('general.insec') }})</label>
        <input
          id="duration"
          name="duration"
          ref="duration"
          type="number"
          class="form-control input"
          :readonly="isDisable || !isNew"
          :data-vv-as="$t('vouchers.duration')"
          v-model="voucher.duration"
          v-validate="'required|min_value:0|integer'"
        />
        <span
          v-tooltip.top-center="{ content: errors.first('duration') }"
          v-show="errors.has('duration')"
          class="help is-danger error_tooltip"
        >
          <i class="fa fa-warning text-error"></i>
        </span>
        <div v-if="!isDisable && isNew" class="d-flex flex-wrap mt-h">
          <button class="btn btn-success mr-h" type="button" @click="handleDurationSelect(1)">
            {{ $t('general.durationDay') }}
          </button>
          <button class="btn btn-success mr-h" type="button" @click="handleDurationSelect(3)">
            {{ $t('portal.duration3Days') }}
          </button>
          <button class="btn btn-success mr-h" type="button" @click="handleDurationSelect(7)">
            {{ $t('general.durationWeek') }}
          </button>
          <button class="btn btn-success" type="button" @click="handleDurationSelect(31)">
            {{ $t('general.durationMonth') }}
          </button>
        </div>
      </div>

      <div class="form-group">
        <label for="session" class="required-mark">{{ $t('vouchers.session') }} ({{ $t('general.insec') }})</label>
        <input
          id="session"
          name="session"
          ref="session"
          type="number"
          class="form-control input"
          :readonly="isDisable || !isNew"
          :data-vv-as="$t('vouchers.session')"
          v-model="voucher.session"
          v-validate="'required|min_value:0|integer'"
        />
        <span
          v-tooltip.top-center="{ content: errors.first('session') }"
          v-show="errors.has('duration')"
          class="help is-danger error_tooltip"
        >
          <i class="fa fa-warning text-error"></i>
        </span>
        <div v-if="!isDisable && isNew" class="d-flex flex-wrap mt-h">
          <button class="btn btn-success mr-h" type="button" @click="handleSessionSelect(1)">
            {{ $t('general.durationDay') }}
          </button>
          <button class="btn btn-success mr-h" type="button" @click="handleSessionSelect(3)">
            {{ $t('portal.duration3Days') }}
          </button>
          <button class="btn btn-success mr-h" type="button" @click="handleSessionSelect(7)">
            {{ $t('general.durationWeek') }}
          </button>
          <button class="btn btn-success" type="button" @click="handleSessionSelect(31)">
            {{ $t('general.durationMonth') }}
          </button>
        </div>
      </div>

      <div class="form-group">
        <label for="used" class="required-mark">{{ $t('vouchers.used') }}</label>
        <select
          id="used"
          name="used"
          class="form-control"
          v-validate="'required'"
          :data-vv-as="$t('vouchers.used')"
          v-model="voucher.used"
          :disabled="isDisable"
        >
          <option :value="true">{{ $t('general.yes') }}</option>
          <option :value="false">{{ $t('general.no') }}</option>
        </select>
        <span
          v-tooltip.top-center="{ content: errors.first('used') }"
          v-show="errors.has('used')"
          class="help is-danger error_tooltip"
        >
          <i class="fa fa-warning text-error"></i>
        </span>
      </div>

      <div slot="modal-footer" class="modal-footer justify-content-between">
        <button
          v-if="!isDisable"
          type="button"
          class="btn btn-outline-success mr-1"
          :disabled="errors.any() || muteEdit"
          @click="handleSave"
        >
          <span :class="{ invisible: muteEdit }">{{ isNew ? $t('vouchers.createVouchers') : $t('general.save') }}</span>
          <span v-if="muteEdit" class="loader loader--mini"></span>
        </button>
        <div>
          <button
            v-if="!isNew && !isDisable"
            type="button"
            class="btn btn-outline-danger mr-2"
            @click="isDeleteOpen = true"
          >
            {{ $t('general.delete') }}
          </button>
          <button type="button" class="btn btn-outline-secondary" @click="cancelEditMode">
            {{ $t('general.close') }}
          </button>
        </div>
      </div>
    </modal>

    <delete-dialog
      :is-open="isDeleteOpen"
      :handle-close="handleCloseDeleteDialog.bind(this)"
      :handle-delete="deleteVoucher.bind(this)"
      :target-text="`${voucher.code} (${getAccountNameByID(voucher.account)}, ${getTariffNameByID(voucher.tariff)})`"
    />

    <close-dialog
      :is-open="isCloseModalOpen"
      :handle-yes="handleConfirmClose.bind(this)"
      :handle-no="closeConfirmModal.bind(this)"
    />
  </div>
</template>

<script>
import CloseDialog from '../../components/close-dialog.vue';
import DeleteDialog from '../../components/delete-dialog.vue';
import EmptyCellValue from '../../components/empty-cell-value.vue';
import Modal from '../../components/Modal.vue';
import TablePaginationV3 from '../../components/table-pagination-v3.vue';
import helpers from '../../helpers';
import portalHotelVouchers from '../../services/portalHotelVouchersService';
import portalTariffsService from '../../services/portalTariffsService';
import portalUserAccountsService from '../../services/portalUserAccountsService';
import portalProfilesService from '../../services/portalProfilesService';
import portalStatsService from '../../services/portalStatsService';

const watcher = new helpers.ChangesWatcher();

export default {
  name: 'PortalHotelVouchers',
  components: {
    CloseDialog,
    DeleteDialog,
    EmptyCellValue,
    Modal,
    TablePaginationV3
  },
  data() {
    return {
      showTablePaginationV3Component: true,
      submitButton: true,
      drPickerDate: {
        startDate: moment().startOf('week').valueOf(),
        endDate: moment().endOf('week').valueOf()
      },
      showStatistics: false,
      offset: 0,
      canLoadMoreVouchers: true,
      totalResultVouchers: false,
      isModalOpen: false,
      isCloseModalOpen: false,
      isDeleteOpen: false,
      isNew: true,
      vouchersAmount: 0,
      voucher: {} /* setting initial value to false breaks something */,
      newVoucher: {
        account: '',
        tariff: '',
        profile: '',
        duration: '',
        session: '',
        id: '',
        used: false,
        number_of_vouchers: 1,
        length_voucher: 4
      },
      filters: {
        accounts: {
          identity: this.$t('portalStats.allAccounts')
        },
        tariffs: { name: this.$t('portalStats.allTariffs') },
        limit: 30,
        offset: 0,
        sort_by: 'account',
        sort_order: 1 /* 1 for ascending, -1 for descending */,
        search: '',
        location: {
          data: {},
          with_childs: false,
        }
      },
      vouchersChartPie: {
        chart: {
          type: 'pie'
        },
        title: {
          text: ''
        },
        subtitle: {},
        plotOptions: {
          pie: {
            size: '100%',
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
              enabled: false
            },
            showInLegend: true
          },
          series: {
            dataLabels: {
              enabled: true,
              format: '{point.name}: {point.y:.1f}%'
            }
          }
        },

        tooltip: {
          headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
          pointFormat:
            '<span style="color:{point.color}">{point.name}</span>: <b>{point.amount}</b> ({point.y:.1f}% of total)<br/>'
        },

        series: [
          {
            name: 'Voucher',
            colorByPoint: true,
            data: []
          }
        ],
        drilldown: {
          series: []
        }
      }
    };
  },
  methods: {
    getFileNameForDownloadFiles(pageName) {
      return helpers.getFileNameForDownloadFiles(pageName)
    },
    convertToNewExcelExportModuleFormat(inputDataForOldExcelModule, nameForExcelList){
      return helpers.convertFromOldExcelModuleFormatToNewExcelModuleFormat(inputDataForOldExcelModule, nameForExcelList)
    },
    setVouchersAmount(amount) {
      this.vouchersAmount = amount;
    },
    redrawTablePaginationV3Component(){
      this.showTablePaginationV3Component = false;
      this.$nextTick(()=>{
        this.showTablePaginationV3Component = true;
      })
    },
    // resetAllFilters() {
    //   this.filters = {
    //     accounts: {
    //       identity: this.$t('portalStats.allAccounts')
    //     },
    //     tariffs: {name: this.$t('portalStats.allTariffs')},
    //     limit: 30,
    //     offset: 0,
    //     sort_by: 'account',
    //     sort_order: 1 /* 1 for ascending, -1 for descending */,
    //     search: '',
    //     location: {
    //       data: {},
    //       with_childs: false,
    //     }
    //   }
    //   this.redrawTablePaginationV3Component();
    //   this.getWithQuery();
    // },
    resetAllFilters() {
      this.filters = {
        accounts: {
          identity: this.$t('portalStats.allAccounts')
        },
        tariffs: {name: this.$t('portalStats.allTariffs')},
        limit: 30,
        offset: 0,
        sort_by: 'account',
        sort_order: 1 /* 1 for ascending, -1 for descending */,
        search: '',
        // location: {
          // data: {},
          // with_childs: false,
        // }
      }
      if (!this.isSelectedDefaultLocationForRequests) {
        this.filters.location = {};
        this.filters.location.data = {};
        this.filters.location.with_childs = false;
      } else {
        this.filters.location = {};
        this.filters.location.data = this.$store.getters.getInHeaderLocationSelectBlockData.selectedLocationObject;
        this.filters.location.with_childs = this.$store.getters.getDefaultWithChildsForRequests(this)
      }
      this.redrawTablePaginationV3Component();
      this.getWithQuery();
    },
    // getVouchersStat() {
    //   this.submitButton = true;
    //   portalStatsService.getVouchersStat(this, 'voucher');
    // },
    changeProfile() {
      this.submitButton = false;
    },
    handleDateChangeForStat() {
      this.submitButton = false;
    },
    loadMore() {
      this.offset++;
      this.filters.offset = this.offset * this.filters.limit;
      // console.log(this.filters.offset);
      // portalHotelVouchers.getVouchers(this, { query: true });
      portalHotelVouchers.getVouchers(this, { query: true, useLocationFilter: true });
    },
    clearQuery() {
      this.offset = 0;
      this.filters.offset = 0;
      this.canLoadMoreVouchers = true;
      this.$store.commit('portalVouchersListClean');
    },
    openModal(isNew, voucher) {
      this.$validator.reset();
      this.isModalOpen = true;
      if (isNew) {
        this.isNew = true;
        this.voucher = { ...this.newVoucher };
      } else {
        this.isNew = false;
        this.voucher = { ...voucher };
        this.voucher.duration = this.voucher.expire_at - this.voucher.start_at;
        delete this.voucher.start_at;
        delete this.voucher.expire_at;
      }
      watcher.reset();
    },
    handleSave() {
      this.$validator.validate().then((valid) => {
        if (valid) {
          if (this.isNew) {
            this.createVoucher(this.voucher);
          } else {
            this.updateVoucher(this.voucher);
          }
        }
      });
    },
    cancelEditMode() {
      this.isModalOpen = false;
      this.isDeleteOpen = false;
      /* Prevents validation blinking during closing animation */
      this.$validator.reset();
    },
    closeConfirmModal() {
      this.isCloseModalOpen = false;
      helpers.handleSecondModalHiding();
    },
    handleConfirmClose() {
      this.closeConfirmModal();
      helpers.handleBothModalsHiding();
      this.cancelEditMode();
    },
    handleModalClick() {
      if (watcher.isChanged) {
        this.isCloseModalOpen = true;
      } else {
        this.cancelEditMode();
      }
    },
    correctValuesTypes(voucher) {
      const copy = { ...voucher };
      const duration = parseInt(copy.duration, 10);
      copy.start_at = moment().unix().valueOf();
      copy.expire_at = copy.start_at + duration;
      delete copy.duration;
      const session = parseInt(copy.session, 10); // WNE-2374 fix
      copy.session = session;
      return copy;
    },
    updateVoucher(voucher) {
      portalHotelVouchers.updateVoucher(this, this.correctValuesTypes({ ...voucher }));
    },
    createVoucher(voucher) {
      portalHotelVouchers.createVoucher(this, helpers.removeID(this.correctValuesTypes({ ...voucher })));
    },
    deleteVoucher() {
      portalHotelVouchers.deleteVoucher(this, this.voucher);
    },
    getTariffNameByID(id) {
      if (this.tariffsList) {
        const result = this.tariffsList.find((item) => item.id === id);
        if (result) {
          return result.name;
        }
        return '';
      }
      return '';
    },
    getAccountNameByID(id) {
      if (this.userAccountsList) {
        const result = this.userAccountsList.find((item) => item.id === id);
        if (result) {
          return result.identity;
        }
        return '';
      }
      return '';
    },
    getProfileNameByID(id) {
      if (this.portalProfilesList) {
        const result = this.portalProfilesList.find((item) => item.id === id);
        if (result) {
          return result.name;
        }
        return '';
      }
      return '';
    },
    getDuration(start, end) {
      if (!start || !end) {
        return '';
      }

      const timeFormat = 'D MMM YYYY, HH:mm';
      const startDate = moment.unix(start).format(timeFormat);
      const endDate = moment.unix(end).format(timeFormat);
      const fromPreposition = this.$t('general.from');
      const toPreposition = this.$t('general.till');

      return `${fromPreposition} ${startDate} ${toPreposition} ${endDate}`;
    },
    getWithQuery() {
      this.clearQuery();
      // portalHotelVouchers.getVouchers(this, { query: true });
      portalHotelVouchers.getVouchers(this, { query: true, useLocationFilter: true });
    },
    handleDurationSelect(days) {
      this.voucher.duration = moment(0).add(days, 'days').unix();
      this.errors.remove('duration');
    },
    handleSessionSelect(days) {
      this.voucher.session = moment(0).add(days, 'days').unix();
      this.errors.remove('session');
    },
    handleCloseDeleteDialog() {
      this.isDeleteOpen = false;
    }
  },
  watch: {
    voucher: {
      deep: true,
      handler() {
        watcher.tick();
      }
    }
  },
  computed: {
    isSelectedDefaultLocationForRequests() {
      if (this.$store.getters.getDefaultLocationForRequests(this)) {
        return true;
      } else {
        return false;
      }
    },
    userBaseLocation() {
      return this.$store.state.userData.base_location;
    },
    // vouchersDataForExport() {
    //   const currentDate = new Date();
    //   // Оставляем только неспользованные и непросроченные
    //   const notUsedVouchers = this.vouchersList.filter(
    //     (voucher) => !voucher.used && currentDate < voucher.expire_at * 1000
    //   );
    //   return notUsedVouchers.map(
    //     (voucher) => `${this.$t('vouchers.code')}: ${voucher.code}. ${this.$t('vouchers.validFor', {
    //       day: moment.duration(voucher.session, 'seconds').asDays()
    //     })}`
    //   );
    // },
    vouchersDataForExport() {
      const currentDate = new Date();
      // Оставляем только неспользованные и непросроченные
      const notUsedVouchers = this.vouchersList.filter(
        (voucher) => !voucher.used && currentDate < voucher.expire_at * 1000
      );
      // console.log(notUsedVouchers)
      const notUsedVouchersResult = notUsedVouchers.map(
        (voucher) => {
          const result = {};
          result[`${this.$t('vouchers.code')}`] = `${voucher.code} `;
          result[`${this.$t('vouchers.validForShort')}`] = `${this.$t('vouchers.validFor', { day: moment.duration(voucher.session, 'seconds').asDays() })}`;
          return result
        }
      );
      // console.log(notUsedVouchersResult)
      return notUsedVouchersResult;
    },
    isFiltersActive(){
      return this.filters.limit !== 30 || this.filters.sort_by !== 'account' ||
        this.filters.sort_order !== 1 || this.filters.search !== '' ||
        (typeof this.filters.location === 'object' && Object.prototype.hasOwnProperty.call(this.filters.location, 'data') &&
        typeof this.filters.location.data  === 'object' && Object.keys(this.filters.location.data).length !== 0 )
    },
    userAccountsStatList() {
      const list = this.$store.state.portalUserAccountsList || [];
      if (list.length && list[0].id) {
        list.unshift({ identity: this.$t('portalStats.allAccounts') });
      }
      return list;
    },
    muteEdit() {
      return this.$store.state.mutePortalVoucherEdit;
    },
    isDisable() {
      return this.$store.state.userData.role === 'operator';
    },
    vouchersList() {
      return this.$store.state.portalVouchersList;
    },
    vouchersStatList() {
      return this.$store.state.portalVouchersStatList;
    },
    userAccountsList() {
      return this.$store.state.portalUserAccountsList;
    },
    portalProfilesList() {
      return this.$store.state.portalProfilesList;
    },
    tariffsList() {
      return this.$store.state.portalTariffsList;
    },
    tariffsStatList() {
      const list = this.$store.state.portalTariffsList || [];
      if (list.length && list[0].id) {
        list.unshift({ name: this.$t('portalStats.allTariffs') });
      }
      return list;
    },
    isVouchersLoading() {
      return (
        this.$store.state.loadingPortalVouchers ||
        this.$store.state.loadingPortalUserAccounts ||
        this.$store.state.loadingPortalTariffs
      );
    }
  },
  // created() {
  //   this.$store.commit('portalTariffsListClean');
  //   this.$store.commit('portalUserAccountsListClean');
  //   this.$store.commit('portalProfilesListClean');
  //   this.clearQuery();
  //   if (this.userAccountsStatList.length < 1) portalUserAccountsService.getUserAccounts(this);
  //   if (this.tariffsList.length < 1) portalTariffsService.getTariffs(this);
  //   if (this.portalProfilesList < 1) portalProfilesService.getPortalProfiles(this);
  //   portalHotelVouchers.getVouchers(this, { query: true });
  //   // this.getVouchersStat();
  // }
  created() {
    this.$store.commit('portalTariffsListClean');
    this.$store.commit('portalUserAccountsListClean');
    this.$store.commit('portalProfilesListClean');
    this.clearQuery();

    let inHeaderLocationSelectedApplying = true;
    if (this.$store.state.inHeaderLocationSelectModeEnabled &&
      inHeaderLocationSelectedApplying) {
      let withChildsForFiltering = this.$store.getters.getInHeaderLocationSelectBlockData.withChilds;
      let locationForFiltering = this.$store.getters.getInHeaderLocationSelectBlockData.selectedLocationID;
      if (!locationForFiltering || locationForFiltering === '') {
        // locationForFiltering = this.$store.getters.getInHeaderLocationSelectBlockData.userBaseLocation
        locationForFiltering = 'All locations';
      }
      if (locationForFiltering === 'All locations') {
        this.filters.location.data = {};
        this.filters.location.with_childs = true;
      } else {
        this.filters.location.data = this.$store.getters.getInHeaderLocationSelectBlockData.selectedLocationObject
        this.filters.location.with_childs = withChildsForFiltering
      }
    }


    // if (this.userAccountsStatList.length < 1) portalUserAccountsService.getUserAccounts(this);
    if (this.userAccountsStatList.length < 1) portalUserAccountsService.getUserAccountsForUseInHotelVouchers(this);
    if (this.tariffsList.length < 1) portalTariffsService.getTariffs(this);
    if (this.portalProfilesList < 1) portalProfilesService.getPortalProfiles(this);
    // portalHotelVouchers.getVouchers(this, { query: true });
    portalHotelVouchers.getVouchers(this, { query: true, useLocationFilter: true });
    // this.getVouchersStat();
  }
};
</script>

<style lang="scss" scoped>
.loader-backdrop-portalvouchers:after {
  position: absolute;
  top: 30px;
}
.section__stats {
  padding-top: 20px;
}
.save-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1;
}
.submit {
  & button {
    // position: relative;
    margin-top: 28px;
    height: 40px;
    width: 100%;
  }
}
.datepicker_width_full {
  width: 100%;
}
.expired-voucher {
  background-color: rgba(227, 232, 236, 0.6);
}
</style>
